import { lazy } from "react";
import { Configuration } from "../configuration";
import RedirectRoute from "./redirectRoute";

export const EmailLoginRoute = lazy(() => import("../pages/login"));
export const LoggedInDashboardRoute = lazy(
  () => import("../pages/loggedIn/user/dashboard")
);
export const FirstPageRoute = lazy(() => import("../pages/login/firstPage"));
export const AdminDashboardRoute = lazy(
  () => import("../pages/loggedIn/admin/dashboard")
);
export const UserChatsRoute = lazy(
  () => import("../pages/loggedIn/admin/userChats")
);
export const UserAccountSettingsRoute = lazy(
  () => import("../pages/loggedIn/user/accountSettings")
);
export const AdminAccountSettingsRoute = lazy(
  () => import("../pages/loggedIn/admin/accountSettings")
);

export const routeList = [
  {
    path: "/",
    element: (
      <>
        <RedirectRoute
          dataObj={{
            routeType: Configuration.routeTypes.noAuth,
          }}
        >
          <FirstPageRoute />
        </RedirectRoute>
      </>
    ),
  },
  {
    path: "/emailLogin",
    element: (
      <>
        <RedirectRoute
          dataObj={{
            routeType: Configuration.routeTypes.noAuth,
          }}
        >
          <EmailLoginRoute />
        </RedirectRoute>
      </>
    ),
  },
  {
    path: "/userDashboard",
    element: (
      <>
        <RedirectRoute
          dataObj={{
            routeType: Configuration.routeTypes.userAuth,
          }}
        >
          <LoggedInDashboardRoute />
        </RedirectRoute>
      </>
    ),
  },
  {
    path: "/adminDashboard",
    element: (
      <>
        <RedirectRoute
          dataObj={{
            routeType: Configuration.routeTypes.adminAuth,
          }}
        >
          <AdminDashboardRoute />
        </RedirectRoute>
      </>
    ),
  },
  {
    path: "/adminUserChats",
    element: (
      <>
        <RedirectRoute
          dataObj={{
            routeType: Configuration.routeTypes.adminAuth,
          }}
        >
          <UserChatsRoute />
        </RedirectRoute>
      </>
    ),
  },
  {
    path: "/userAccountSettings",
    element: (
      <>
        <RedirectRoute
          dataObj={{
            routeType: Configuration.routeTypes.userAuth,
          }}
        >
          <UserAccountSettingsRoute />
        </RedirectRoute>
      </>
    ),
  },
  {
    path: "/adminAccountSettings",
    element: (
      <>
        <RedirectRoute
          dataObj={{
            routeType: Configuration.routeTypes.adminAuth,
          }}
        >
          <AdminAccountSettingsRoute />
        </RedirectRoute>
      </>
    ),
  },
];
