import { Token, LoggedUserDatas } from "./manageLocalStorage";
import { Configuration } from "../configuration";
import { Navigate } from "react-router-dom";

/**
 * Redirect route
 *
 * @param {object} dataObj
 * @param {any} dataObj.inputRoute
 * @param {int} dataObj.routeType
 *
 * @returns {object}
 */
const RedirectRoute = ({ children, dataObj }) => {
  if (
    Token.hasToken() &&
    LoggedUserDatas.getUserDatas("usertype_id") ===
      Configuration.routeTypes.userAuth &&
    dataObj?.routeType === Configuration.routeTypes.noAuth
  ) {
    return <Navigate to="/userDashboard" />;
  } else if (
    Token.hasToken() &&
    LoggedUserDatas.getUserDatas("usertype_id") ===
      Configuration.routeTypes.adminAuth &&
    dataObj?.routeType === Configuration.routeTypes.noAuth
  ) {
    return <Navigate to="/adminDashboard" />;
  } else if (
    !Token.hasToken() &&
    dataObj?.routeType !== Configuration.routeTypes.noAuth
  ) {
    return <Navigate to="/emailLogin" />;
  }

  return children;
};

export default RedirectRoute;
