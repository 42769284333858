import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { routeList, EmailLoginRoute } from "./services/routes";
import Layout from "./components/layouts/comonLayout";

const App = () => {
  const router = createBrowserRouter([
    {
      element: <Layout />,
      errorElement: <EmailLoginRoute />,
      children: routeList,
    },
  ]);

  return <RouterProvider router={router} />;
};

export default App;
