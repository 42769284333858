import { createSlice } from "@reduxjs/toolkit";

const knowledgeBaseSlice = createSlice({
  name: "knowledgeBase",
  initialState: {
    isShow: false,
    isSearchShow: false,
    searchText: "",
    isSearchSourceShow: false,
    searchSourceList: [],
    searchChatId: "",
    isShowHome: false,
    pageName: "searchBox",
    isNewChat: false,
    isNewChatClick: false,
  },
  reducers: {
    setIsShow: (state, action) => {
      state.isShow = action.payload;
    },
    setIsSearchShow: (state, action) => {
      state.isSearchShow = action.payload;
    },
    setSearchText: (state, action) => {
      state.searchText = action.payload;
    },
    setIsSearchSourceShow: (state, action) => {
      state.isSearchSourceShow = action.payload;
    },
    setSearchSourceList: (state, action) => {
      state.searchSourceList = action.payload;
    },
    setSearchChatId: (state, action) => {
      state.searchChatId = action.payload;
    },
    setIsShowHome: (state, action) => {
      state.isShowHome = action.payload;
    },
    setPageName: (state, action) => {
      state.pageName = action.payload;
    },
    setIsNewChat: (state, action) => {
      state.isNewChat = action.payload;
    },
    setIsNewChatClick: (state, action) => {
      state.isNewChatClick = action.payload;
    },
  },
});

export const {
  setIsShow,
  setIsSearchShow,
  setSearchText,
  setIsSearchSourceShow,
  setSearchSourceList,
  setSearchChatId,
  setIsShowHome,
  setPageName,
  setIsNewChat,
  setIsNewChatClick,
} = knowledgeBaseSlice.actions;
export default knowledgeBaseSlice.reducer;
