export const Configuration = {
  backend: {
    sendLoginCode: "/users/mail_code",
    verfiyEmailCode: "/users/login",
    sendSMSCode: "/users/sms_code",
    verifySendSMSCode: "/users/verify_phone_code",
    updateUserDetails: "/users/user_details",
    getFaq: "/assistants/faqs",
    getKnowledgeLibraries: "/files/gdrive_files",
    getUsers: "/users/list_users",
    getSearch: "/assistants/search",
    editUserDatas: "/users/users/{userId}/update_details",
    googleLogin1: "/users/auth/google",
    googleLogin2: "/users/auth",
    getUserHistories: "/users/users_history",
    refreshToken: "/users/refresh_token",
  },
  routeTypes: {
    noAuth: 0,
    adminAuth: 1,
    userAuth: 2,
  },
  googleLogin: {
    emailCode: "666111",
  },
};
